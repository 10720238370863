import React from 'react';
import Card from 'react-bootstrap/Card';

const Quote = ({quote, index}) => {
    const { text, author } = quote;
    return (
        <Card className={index%2 === 0 ? 'bg-light border-light' : 'bg-dark text-white'}>
            <Card.Body>
                <blockquote className="blockquote mb-0">
                    <p>
                    {' '}
                    {text}
                    {' '}
                    </p>
                    <footer className="blockquote-footer">
                        {author}
                    </footer>
                </blockquote>
            </Card.Body>
        </Card>
    );
};

export default Quote;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => (
    <Container>
        <hr></hr>
        <Row>
            <Col sm="12" md>
                <p className="text-center" style={{fontSize: '14px'}}>
                    © 2021 Copyright :
                    <a className="text-dark" href="https://nmnithinkrishna.me/"> nmnithinkrishna.me</a>
                </p>
            </Col>
            <Col sm="8" md>
                <p className="text-center text-muted" style={{fontSize: '12px'}}>
                    Thanks to <a href="https://reactjs.org/">React, </a>
                    <a href="https://react-bootstrap.github.io/">Bootstrap, </a>
                    <a href="https://fontawesome.com/">Fontawesome, </a>
                    <a href="https://www.flaticon.com/">Flaticon, </a>
                    <a href="https://drive.google.com/drive">Gdrive, </a>
                    <a href="https://www.google.co.in/imghp?hl=en&authuser=0&ogbl">Gimages  </a>
                </p>
            </Col>
            <Col sm="4" md>
                <p className="text-center text-muted" style={{fontSize: '12px'}}>Your data is safe, Spamming is not my <span aria-label="cup of tea" role="img" style={{fontSize: '16px'}}>☕</span></p>
            </Col>
        </Row>
    </Container>
);

export default Footer;
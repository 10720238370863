import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';

const Intro = () => (
    <Container name="home__intro">
        <Row>
            <Col sm={12} md className="align-center">
                <Image alt="nmnithinkrishna" src='/media/intro.png' thumbnail className="avatar" width="440" />
            </Col>
            <Col sm={12} md className="align-center intro-content">
                <p>Hey there!</p>
                <h1 className="color__royalblue">I'm Nithin Krishna...</h1>
                <p>Software Engineer passionate about Technology.</p>
                <p>Programming intelligence in bots to perform tedious business tasks, processes using UiPath suite.</p>
                <div className="align-badges">
                    <Badge variant="warning" className="badge-text">Problem Solving</Badge>
                    <Badge variant="warning">RPA UiPath</Badge>
                    <Badge variant="warning">.NET FullStack</Badge>
                    <Badge variant="warning">JavaScript</Badge>
                </div>
                <hr></hr>                    
                <div className="inspired-quote">
                    <Alert variant="warning" className="text-center">
                        C o d e &nbsp; n e v e r &nbsp; l i e s !
                    </Alert>
                </div>
            </Col>
        </Row>
    </Container>
);

export default Intro;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';

const WhoGaveMeHope = () => (
    <Container>
        <Row>
            <Col>
                <h2 className="text-center color__royalblue">Who gave me hope</h2>
                <hr width="50" color="red"></hr>
            </Col>
        </Row>
        <br></br>
        <Row>
            <Col md={12} lg={7}>
                <Figure>
                    <Figure.Image src="/media/dr_apj_abdul_kalam.png"></Figure.Image>
                </Figure>
            </Col>
            <Col>
                <Row>
                    <Col className="align-center" style={{margin: 'auto'}}>
                        <Figure>
                            <Figure.Image width="160" src="/media/ms_dhoni.png"></Figure.Image>
                        </Figure>
                    </Col>
                    <Col className="align-center" style={{margin:'auto'}}>
                        <Figure>
                            <Figure.Image src="/media/elon_musk.png"></Figure.Image>
                        </Figure>
                    </Col>
                    
                </Row>
                <Row>
                    <Col className="align-center" style={{margin: 'auto'}}>
                        <Figure>
                            <Figure.Image width="160" src="/media/rajini_kanth.png"></Figure.Image>
                        </Figure>
                    </Col>
                    <Col className="align-center" style={{margin: 'auto'}}>
                        <Figure>
                            <Figure.Image width="160" src="/media/ArnoldSchwarzenegger.png"></Figure.Image>
                        </Figure>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
);

export default WhoGaveMeHope;
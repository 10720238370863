import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
    <Container>
        <Row>
            <Col>
                <p className="text-center">Thanks for being here, Hope that was interesting <span role="img" aria-label="thumbs-up"> 👍</span></p>
                <p className="text-center text-muted">If you still haven't got something you want, feel free to <a href="/contact">Reach me</a></p>
            </Col>
        </Row>
    </Container>
);

export default Footer;
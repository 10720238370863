import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

const WhatMakesMyDay = () => (
    <Container>
            <Row>
                <Col>
                    <h2 className="text-center color__royalblue">What makes my day! </h2>
                    <hr width="50" color="red"></hr>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col md={6} lg>
                    <Alert variant="secondary" className="text-center makemyday-card">
                        Believe
                        <hr></hr>
                        <p>First and foremost I believe in myself before starting anything.</p>
                    </Alert>
                </Col>
                <Col md={6} lg>
                    <Alert variant="secondary" className="text-center makemyday-card">
                        Try
                        <hr></hr>
                        <p>I try hitting it hard using every opportunity no matter what.</p>
                    </Alert>
                </Col>
                <Col md={12} lg>
                    <Alert variant="secondary" className="text-center makemyday-card">
                        Patience
                        <hr></hr>
                        <p>I'm always honest to myself and take on chances until I achieve.</p>
                    </Alert>
                </Col>
            </Row>
        </Container>
);

export default WhatMakesMyDay;
import googleFormPost from '../utilities/google-form-post';

export default (email) => {

    const newsletter = {
        id: '1FAIpQLSc8diZLY9eg9VOf6ZOrbNUaE8Zi7Ld4eSzKDrhGK1JgasVIsA',
        data: `entry.34154752=${email}`
    };

    googleFormPost(newsletter);
};
import React from 'react';
import Intro from '../components/sections/About/Intro';
import Highlights from '../components/sections/About/Highlights';
import Testimonials from '../components/sections/About/Testimonials';
import Contributions from '../components/sections/About/Contributions';
import Resume from '../components/sections/About/Resume';
import Footer from '../components/sections/About/Footer';

const About = () => (
    <>
        <Intro />
        <br></br>
        <br></br>
        <br></br>
        <Highlights />
        <br></br>
        <br></br>
        <br></br>
        <Testimonials />
        <br></br>
        <br></br>
        <br></br>
        <Contributions />
        <br></br>
        <br></br>
        <br></br>
        <Resume />
        <br></br>
        <br></br>
        <br></br>
        <Footer />
        <br></br>
        <br></br>
    </>
);

export default About;
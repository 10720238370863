import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Intro = () => {

    return (
        <Container className="my-5">
            <br></br>
            <br></br>
            <Row className="d-none">
                <Col>
                    <h2 className='text-center mt-5 color__royalblue'>Vision</h2>
                    <hr width="50px" color="red"></hr>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3 className="text-center">Vision: Help solve business & social problems by the best use of technology.</h3>
                </Col>
            </Row>
        </Container>
    );
};

export default Intro;
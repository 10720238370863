import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const Resume = () => {

    return (
        <Container>
            <Row>
                <Col>
                    <h2 className='text-center mt-5 color__royalblue'>Get my full profile now</h2>
                    <hr width="50px" color="red"></hr>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col className="align-center">
                    <Button variant="outline-success" href="https://drive.google.com/file/d/1GmJDPAFniTA9zhz9SL4cthwVt6TZzKm4/view?usp=sharing" target="_blank"><h3>View/Download from gDrive</h3></Button>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col className="align-center">
                    <Container>
                        <Row>
                            <iframe
                                style={{borderRadius: '5px'}}
                                title="nmnithinkrishna-resume"
                                src="/media/nmnithinkrishna.pdf" 
                                width="100%"
                                height="1200"
                            />
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Resume;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faHackerrank } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

//#region - brand colors
const linkedInBrandColor = '#0A66C2';
const githubBrandColor = '#212121'
const hackerRankBrandColor = '#1BA94C';
const gmailBrandColor = '#D30000';
//#endregion

//#region - social media profile links
const linkedInProfile = 'https://www.linkedin.com/in/nmnithinkrishna/';
const githubProfile = 'https://github.com/nmnithinkrishna';
const hackerRankProfile = 'https://www.hackerrank.com/nmnithinkrishna';
const gmailProfile = 'mailto:nmnithinkrishna@gmail.com';
//#endregion

const SocialMenu = () => (
    <div className="social-menu">
        <span className="social-menu__icon">
            <a href={linkedInProfile} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="lg" color={linkedInBrandColor} />
            </a>
        </span>
        <span className="social-menu__icon">
            <a href={githubProfile} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} size="lg" color={githubBrandColor} />
            </a>
        </span>
        <span className="social-menu__icon">
            <a href={hackerRankProfile} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faHackerrank} size="lg" color={hackerRankBrandColor} />
            </a>
        </span>
        <span className="social-menu__icon">
            <a href={gmailProfile} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faEnvelopeSquare} size="lg" color={gmailBrandColor} />
            </a>
        </span>
    </div>
);

export default SocialMenu;
import React, {useContext} from 'react';
import Quote from './Quote';

import tacosContext from '../../../context/tacos-context';

const Quotes = () => {

    const { tacos } = useContext(tacosContext);

    return tacos.reverse().map((quote) => (
        <Quote key={quote.id} quote={quote} index={quote.id} />
    ));
};

export default Quotes;
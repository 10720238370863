import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

const Menu = () => {
    
    const location = useLocation();
    const history = useHistory();

    const [menu, setMenu] = useState('');

    useEffect(() => {
        let currentPage = location.pathname.replace('/', '');
        currentPage = currentPage.length > 0 ? currentPage : 'home';
        setMenu(currentPage);
        document.title = currentPage + ' | nmnithinkrishna, technologist'
    }, [location]);

    return (
    <div name="navbar">
        <Container>
            <Row>
                <Col>
                    <Nav variant="pills" justify={true} activeKey={menu} onSelect={(selectedKey) => history.push(`/${selectedKey}`)}>
                        <Nav.Item>
                            <Nav.Link eventKey="home">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="about">About</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="blog">Blog</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="tacos">Tacos</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="contact">Contact</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <br></br>
        </Container>
    </div>
)};

export default Menu;
import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faHackerrank } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {

    return (
        <Container>
            <br></br>
            <br></br>
            <Row>
                <Col className="text-center">
                    <h1 className="color__royalblue">Connect with me...</h1>
                    <hr width="50" color="red"></hr>
                    <p>For any questions, hiring, training, discussion & literally anything without hesitation</p>
                    <br></br>
                    <br></br>
                </Col>
            </Row>
            <Row>
                <Container fluid>
                    <Row className="align-center">
                        <iframe title="contact" src="https://docs.google.com/forms/d/e/1FAIpQLSff69NW80n-FakxNyamhvlYLVxDQyOEQbTbYbbm-seFeIithg/viewform?embedded=true" width="100%" height="970" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </Row>
                </Container>
            </Row>
            <br></br>
            <Row>
                <Col className="text-center text-muted">
                    Follow me on &nbsp;
                    <FontAwesomeIcon icon={faLinkedin} size="md" />
                    &nbsp;&nbsp;
                    <FontAwesomeIcon icon={faGithub} size="md" />
                    &nbsp;&nbsp;
                    <FontAwesomeIcon icon={faHackerrank} size="md" />
                    &nbsp;&nbsp;
                    <FontAwesomeIcon icon={faEnvelopeSquare} size="md" />
                    &nbsp;
                    @ /nmnithinkrishna
                </Col>
            </Row>
            <br></br>
        </Container>
    );
};

export default Contact;
import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import { faQuora, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BlogExcerpt = () => {

    const [ id, setId ] = useState(0);

    return (
        <Container>
            <Row>
                <Col>
                    <h2 className="text-center color__royalblue">Check out my blog...</h2>
                    <hr width="50" color="red"></hr>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col className="text-center">
                    <FontAwesomeIcon icon={faQuora} size="lg" color="#A82723" opacity="0.9" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faMedium} size="lg" color="#000" opacity="0.9" />
                </Col>
            </Row>
            <br></br>
            <br></br>
            <Row className="p-2 d-none d-md-flex">
                <Col>
                    <Row onClick={() => setId(0)} className={`${id === 0 && 'blog-category'} text-center p-3`}>
                        <Col>
                            <h3>T e c h n o l o g y</h3>
                            <p className="text-muted">Handcrafted content on latest news, updates, tutorials, hacks which includes .NET, JavaScript, RPA and more...</p>
                        </Col>
                    </Row>
                    <Row onClick={() => setId(1)} className={`${id === 1 && 'blog-category'} text-center p-3`}>
                        <Col>
                            <h3>L i f e</h3>
                            <p className="text-muted">Best information on simple things much needed for life which can make it beautiful and interesting...</p>
                        </Col>
                    </Row>
                    <Row onClick={() => setId(2)} className={`${id === 2 && 'blog-category'} text-center p-3`}>
                        <Col>
                            <h3>F i t n e s s</h3>
                            <p className="text-muted">Key things on how to be healthy and fit by doing small things consistently for a short period of time...</p>
                        </Col>
                    </Row>
                    <Row onClick={() => setId(3)} className={`${id === 3 && 'blog-category'} text-center p-3`}>
                        <Col>
                            <h3>W e a l t h</h3>
                            <p className="text-muted">Vital details on finance and investment to keep us away from being down and out...</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className={`p-2 ${id === 0 ? 'd-block' : 'd-none'}`}>
                        <Col>
                            <h5 className="text-muted text-center">P I C K E D &nbsp; F O R &nbsp; Y O U</h5>
                            <hr></hr>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>UiPath - RPA's major contributor</p>
                                    <p className="text-muted">RPA being the trending tech for the last few years</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Robocorp - An open source RPA disruption</p>
                                    <p className="text-muted">Gonna rock with the attribute open source & python</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>JavaScript - The game changer</p>
                                    <p className="text-muted">Making revolution in the product market</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Blazor - .NET JavaScript framework</p>
                                    <p className="text-muted">Build client side apps using C#</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={`p-2 ${id === 1 ? 'd-block' : 'd-none'}`}>
                        <Col>
                            <h5 className="text-muted text-center">P I C K E D &nbsp; F O R &nbsp; Y O U</h5>
                            <hr></hr>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Procrastination - How bad is it?</p>
                                    <p className="text-muted">It can ruin your journey day by day</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Are you really being productive</p>
                                    <p className="text-muted">Planning your day or week helps</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Doing something for 30 days</p>
                                    <p className="text-muted">Develop a quality habit in no time</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Is accountability a required attribute</p>
                                    <p className="text-muted">Being accountable helps you succeed</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={`p-2 ${id === 2 ? 'd-block' : 'd-none'}`}>
                        <Col>
                            <h5 className="text-muted text-center">P I C K E D &nbsp; F O R &nbsp; Y O U</h5>
                            <hr></hr>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Walk 1km every day</p>
                                    <p className="text-muted">Walking 1km a day keeps the doctor away</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>8 hours of sleep</p>
                                    <p className="text-muted">Sleep cycles are very important for a good hygiene</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Adding stretches to daily routine</p>
                                    <p className="text-muted">Mental strength and mobility improvement</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Drink 4-5 litres of water a day</p>
                                    <p className="text-muted">If you want to experience age is just a number</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={`p-2 ${id === 3 ? 'd-block' : 'd-none'}`}>
                        <Col>
                            <h5 className="text-muted text-center">P I C K E D &nbsp; F O R &nbsp; Y O U</h5>
                            <hr></hr>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Having multiple sources of income</p>
                                    <p className="text-muted">Diversify yourself to avoid facing financial risk</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Health insurance is an investment</p>
                                    <p className="text-muted">Being insured for health is a must</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>1% of income to charity</p>
                                    <p className="text-muted">It's time to give something back to the society</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                            <Row className="p-3 pl-5">
                                <Col>
                                    <p>Loan vs Investment portfolio</p>
                                    <p className="text-muted">Build investment portfolio to avoid debts</p>
                                    <hr></hr>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="p-2 d-block d-md-none">
                <Carousel className="text-center d-block w-100" 
                    prevIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" color="red" />}
                    nextIcon={<FontAwesomeIcon icon={faChevronRight} size="lg" color="red" />}
                >
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col>
                                    <h2>Technology</h2>
                                    <hr width="50%"></hr>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        UiPath - RPA's major contributor
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            RPA being the trending tech for the last few years.
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Robocorp - An open source RPA disruption
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Gonna rock with the attribute open source & python.
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Blazor - .NET JavaScript framework
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Build client side apps using C#.
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        JavaScript - The game changer
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Making revolution in the product market.
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col>
                                    <h2>Life</h2>
                                    <hr width="50%"></hr>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Procrastination - How bad is it?
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            It can ruin your journey day by day
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Are you really being productive
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Planning your day or week helps
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Doing something for 30 days
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Develop a quality habit in no time
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Is accountability a required attribute
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Being accountable helps you succeed
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col>
                                    <h2>Fitness</h2>
                                    <hr width="50%"></hr>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Walk 1km every day
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Walking 1km a day keeps the doctor away
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        8 hours of sleep
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Sleep cycles are very important for a good hygiene
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Adding stretches to daily routine
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Mental strength and mobility improvement
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Drink 4-5 litres of water a day
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            If you want to experience age is just a number
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Container>
                            <Row>
                                <Col>
                                    <h2>Wealth</h2>
                                    <hr width="50%"></hr>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Having multiple sources of income
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Diversify yourself to avoid facing financial risk
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Health insurance is an investment
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Being insured for health is a must
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        1% to charity every month
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            It's time to give something back to the society
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <p>
                                        Loan vs Investment portfolio
                                        <br></br>
                                        <span className="text-muted blog-subtitle__mobile">
                                            Build investment portfolio to avoid debts
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                </Carousel>
            </Row>
        </Container>
    );
};

export default BlogExcerpt;
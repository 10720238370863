import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const KnowMoreAboutMe = () => (
    <Container>
        <Row>
            <Col className="text-center">
                <Button variant="outline-success" href="/about"><h2>Know more about me</h2></Button>
            </Col>
        </Row>
    </Container>
);

export default KnowMoreAboutMe;
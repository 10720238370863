import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getDatabase, ref, onValue } from "firebase/database";
import { getFirestore, doc, getDoc } from "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyC9nu9bgNtVx7du8JPir3XlB9FOLZtLIwg",
  authDomain: "nmnithinkrishna-my-site.firebaseapp.com",
  databaseURL: "https://nmnithinkrishna-my-site-default-rtdb.firebaseio.com",
  projectId: "nmnithinkrishna-my-site",
  storageBucket: "nmnithinkrishna-my-site.appspot.com",
  messagingSenderId: "617167239229",
  appId: "1:617167239229:web:fcf8b7d20d21fff3aa9254",
  measurementId: "G-P2H0Z7PJW6"
};

const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdmoD8bAAAAADUP_3ueqv5uthEBFDdekOQitZ9d'),
  isTokenAutoRefreshEnabled: true
});

const rt_db = getDatabase(app);
const tacos_db = ref(rt_db, 'tacos');
const tacos_list = [];
onValue(tacos_db, (snapshot) => {
  snapshot.forEach((childSnap) => {
      tacos_list.push({
        id: childSnap.key,
        ...childSnap.val()
      });
  });
}, {
  onlyOnce: true
});

const fs_db = getFirestore(app);
const docRef = doc(fs_db, "blog/blog-coming-soon");
let blog = "";
getDoc(docRef).then((docSnap) => {
  blog = docSnap.data().content;
});

export { tacos_list, blog };
import React from 'react';
import Intro from './sections/Home/Intro';
import WhatMakesMyDay from './sections/Home/WhatMakesMyDay';
import WhoGaveMeHope from './sections/Home/WhoGaveMeHope';
import KnowMoreAboutMe from './sections/Home/KnowMoreAboutMe';
import BlogExcerpt from './sections/Home/BlogExcerpt';
import TacosSummary from './sections/Home/TacosSummary';
import SubscribeTo from './sections/Home/SubscribeTo';

const Home = () => {
    
    return (
    <div name="home">   
        <Intro />
        <br></br>
        <br></br>
        <br></br>
        <WhatMakesMyDay />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <WhoGaveMeHope />
        <br></br>
        <br></br>
        <KnowMoreAboutMe />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <BlogExcerpt />
        <br></br>
        <br></br>
        <br></br>
        <TacosSummary />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <SubscribeTo />
    </div>
)};

export default Home;
import React, { useEffect, useReducer } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFireAlt } from '@fortawesome/free-solid-svg-icons';
import CardColumns from 'react-bootstrap/CardColumns';
import Quotes from './sections/Tacos/Quotes';

import tacosContext from '../context/tacos-context';
import tacosReducer from '../reducers/tacos';
import { tacos_list } from '../utilities/firebase-db';

const Tacos = () => {

    const [tacos, dispatch] = useReducer(tacosReducer, []);

    useEffect(() => { dispatch({ type: 'POPULATE_TACOS', tacos: tacos_list }); }, []);

    return (
        <tacosContext.Provider value={{tacos, dispatch}}>
            <div name="tacos">
                <br></br>
                <Container>
                    <Row>
                        <Col className="align-center">
                            <h2 className="color__royalblue page-title">
                                Get your morning energy..&nbsp;
                                <FontAwesomeIcon icon={faFireAlt} size="xs" color="#e25822" />
                            </h2>
                        </Col>
                    </Row>
                    <hr width="250" color="red"></hr>
                    <br></br>
                    <Row>
                        <Col>
                            <CardColumns>
                                    <Quotes />
                            </CardColumns>
                        </Col>                
                    </Row>
                </Container>
            </div>
        </tacosContext.Provider>
)};

export default Tacos;
import React from "react";

const BlogTest = () => {
   return (
    <div class="container">
        <br /><br />
        <div class="row">
            <h1 class="text-center col color__royalblue">
                Walking 1km a day, keeps the doctor away !
            </h1>
        </div>
        <hr width="50" color="red"></hr>
        <div class="row text-muted text-center">
            <div class="col">
                Health & Fitness
            </div>
        </div>
        <br /><br />
        <div class="row mx-5">
            <div class="col">
                Most probably we all are well aware about our childhood quote, "An apple a day keeps the doctor away".
                <br /><br />
                So, Now I want to discuss something equally important to that. Let's get into it.
                <br /><br />
                Yes, it's Walking.
                <br /><br /><br />
                <h2>What is Walking ?</h2>
                <br />
                It is a physical activity performed by lifting our feet and placing it down again a bit far from where we lifted it.
                <br /><br />
                Crazy textbook definition is it :)
                <br /><br />
                <img alt='Walking activity' src='https://c.tenor.com/gyXd5XomMNQAAAAC/stickman-walking.gif' style={{width:'250px'}} />
                <br /><br /><br />
                <h2>Why to Walk ?</h2>
                <br />
                Our body is a combination of various systems like respiratory, musculo-skeletal, nervous etc. co-ordinating among themselves to ensure proper functioning which in turn results in a good health.
                <br /><br />
                At times due to lack of health discipline we may not allow proper functioning of the system which in turn leads to health hazards.
                <br /><br />
                So, It's important to go for a walk every day to maintain proper functioning of systems. Some of the vital benefits are below,
                <br /><br />
                &nbsp;&nbsp;&nbsp; - Breathing fresh air improves supply of Oxygen to the body
                <br /><br />
                &nbsp;&nbsp;&nbsp; - Better the Oxygen supply, Better the muscle rejuvenation (getting younger)
                <br /><br />
                &nbsp;&nbsp;&nbsp; - Healthy heart rate with less to no chance for cardiac illness
                <br /><br />
                &nbsp;&nbsp;&nbsp; - Improved mobility ensuring bone and muscle strength
                <br /><br />
                &nbsp;&nbsp;&nbsp; - Mental relief with active mood throughout the day
                <br /><br />
                &nbsp;&nbsp;&nbsp; - Helps you build quality sleep routine with proper sleep cycle
                <br /><br />
                &nbsp;&nbsp;&nbsp; - Stimulates hunger with better digestion
                <br /><br />
                The benefits are actually endless. Hope the above points are sufficient to convince us to practise walking as a habit.
                <br /><br />
                <img alt="Morning Walk" src="https://lh3.googleusercontent.com/_-G64PP53tKS4Qfxkt8kS8jmp5bm5_b9zGI2Xwa31xBQxnxNCGk3g7-5n_P5Avf6Kmv-sLO2GIY6pk5SPpgBnNYAZYY33ddWoGcfy8rGQh4WDPQO_gFjOX22WypWK_j46eQvPyeLJNDLSjVF8l-3dEbIf6udQUmfieUCao5QqKJdsk2fPndutE4SYYnfQoxPnWksXwxCwp0BIo27556NZsa2MY7BlVux3LAXO8gCKMpTUWRYg_rI4KYLI_kXrCngn8tDRqpxoh0K0Pz4uYUh5X3R6sOqsREWYTa6Vwu_3ZLv1CLn29hwA5C4ce2ykSsL7tvUxJYcOHEPy6_4YUXI7jb44iJaEBuyd0Ma-8KZDu5fe4fFtq61MQBYMVsFAjAzqwnqfSIh-quOADIaRQ8FuulnMgu3YK6E_jwEPsFTQRuEnxZ5RO4ME9p2HIXSx8iaeKFewOg1Cc58UMXa3PdcGRdDbfUtmt62udwTRzJouU_Hj1CXUDYMC61-VSJnIpsmVFHKmPQGDwAPGAs6fMPvlpBF5yFUnSm0xxBW3ficYQF4nH7HSukpyNN_XB7E0vtewwimjT9bIlKQaK1cqGZVktJEj001ght_xn_97RLdY2ynMEpg1rpgkTIVHujCelobvNKmgW72K97jrUNdUK9AC6HmG32oVVW0OlN9vTjB8ohwpwZMwqyaIOMjDtE0LWY3HUpX8zg7ucvi-gh-9si1_fU=w986-h657-no?authuser=1" style={{width:'250px'}} />
                <br /><br /><br />
                <h2>Who can Walk ?</h2>
                <br />
                The answer is actually, Anyone. It's highly recommended & of course mandatory to get doctor's advise before you start anything.
                <br /><br />
                Also, There are few Myths to be debunked like only fat people can walk or people of certain age. But a kid to granny, no matter.
                <br /><br /><br />
                <h2>Morning / Evening ?</h2>
                <br />
                I personally prefer Morning, as things are pretty fresh with less amount of pollution. Rising Sun with trees releasing fresh oxygen :)
                <br /><br />
                But nothing wrong in the Evenings as well, based on the daily plan we have. 
                Noon can be avoided due to scorching sun with polluted environment which also doesn't benefit us.
                <br /><br />
                <img alt="Morning Walk" src="https://lh3.googleusercontent.com/fVl99LTpbfEJu3cCFk6KQAhlNmlrN_8-Mpc98tGOBBxeqkTNeaKl7t13UY1QKMhkxPLVlu937w_ofpnrhqg8ECzqwKJZjIMnfGtivUhLt9FhhykZfO1OO3jb4lf69KsENXmvRBOOvWRgQq2ZrjsVLTQqgBibWZ5wPVKP6ZpW-Nt2WYVYjbVjYbAEEODhGI5WT1tbqu02LYiK-I4FYjGtTOnQOUYRst043LBpUh_SCK6CubF7g22WqzPI8UJ-TbCntkvm2-XplHDQQRKEH1_0FW_RfTp6Yence5tkVlN-Uf48YaVxzLE1UNd4CxwtDaVmkeSiEUsahB0o__BehWLDbO2BdwN8LQ-9GGFIqK9XJdYQaWfT5O2ODndam_XRwz3S1FKkqPW-IJ_ABhjb9yy0HRup2xkorJCK8cAYXVAfuhzcitcUfuCxjbdfM1kI9waHmtiQw6cE0BSn2lRvYdP6a151xgQtgLahypLY-OX8uhrU8s3AOU02aug4oDXAtHhDPHmKqnr-0X5fV_mV2eJGd5sdGzsanTU14CIy-TxGympD5Dyx4EH87bjOfso4yE5zew6heJR1AEAHS0BgIhoy55DH_BevMUwWu4qNMRsPr3gSthsskUdZT1PbsRKhfMrad_jaJYVDMNYaVbxmvW_vmY8qbogUhCIEAXVYRzaq4-SYJ_jkTeKOmzmeJxC5oBbkBE_dnB1-QiCdT0fkJoPBc_U=w439-h657-no?authuser=1" style={{width:'250px'}} />
                <br /><br />
                Hope it's time to try it now, Right !
                <br /><br />< br /> 
                <h2>Quick Gist</h2>
                <br />
                &nbsp;&nbsp;&nbsp; - Walking is one of the most important habit in life
                <br /><br />
                &nbsp;&nbsp;&nbsp; - It helps to prolong life span & make you feel young with energy
                <br /><br />
                &nbsp;&nbsp;&nbsp; - It's more effective as a morning routine with added benefits
                <br /><br /><br />
                <small class="text-muted xs">Disclaimer: I'm neither a doctor nor health physician nor anyway related to health, All these information shared is on my own social interest with my own study and experience. Kindly take a proper doctor's advise before you try anything related to health.</small>
                <br /><br />
            </div>
        </div>
    </div>
   );
};

export default BlogTest;
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import newsletterSubmit from '../../../forms/newsletter';

const SubscribeTo = () => {

    const [subEmail, setSubEmail] = useState('');

    const addSubscriber = (e) => {
        e.preventDefault();
        if(new RegExp('^[^@]{3,}@{1}[^@]{3,}\.{1}[^@,.]{2,}$').test(subEmail)) {
            newsletterSubmit(subEmail);
            alert('Added to subscribers list. As promised quality content will reach your inbox. Thanks.');
            setSubEmail('');
        }
        else {
            alert('Invlid email, please try again.')
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h2 className="text-center color__royalblue">Let me share things :)</h2>
                    <hr width="50" color="red"></hr>
                </Col>
            </Row>
            <br></br>
            <br></br>
            <Form onSubmit={addSubscriber}>
                <Row>
                    <Col className="align-center" sm={12} md>
                        <p>
                            <span role="img" aria-label="newsletter">📰 </span> Get latest information, updates, news, blog...
                        </p>
                    </Col>
                    <Col className="align-center" sm={12} md> 
                        <Col sm="8">
                            <Form.Control placeholder="Email" type="email" value={subEmail} onChange={(e) => setSubEmail(e.target.value)} required />
                        </Col> 
                        <Col>
                            <Button type="submit">Subscribe</Button>  
                        </Col>   
                    </Col>
                </Row>
            </Form>    
            <br></br>
            <br></br>
        </Container>
    );
};

export default SubscribeTo;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardColumns from 'react-bootstrap/CardColumns';
import Card from 'react-bootstrap/Card';
import { useHistory } from 'react-router';

const TacosSummary = () => {

    const history = useHistory();

    const navToTacos = () => {
        history.push('/tacos');
        window.scrollTo(0, 0);
    };

    return (
        <Container onClick={navToTacos}>
            <Row>
                <Col>
                    <h2 className="text-center color__royalblue">Few handwritten lines</h2>
                    <hr width="50" color="red"></hr>
                </Col>
            </Row>
            <br></br>
            <br></br>
            <Row>
                <Col className="m-auto">
                    <h3>Anytime when you feel down</h3>
                    <br></br>
                    <p>These are few facts penned down from real experiences <span role="img" aria-label="charge">⚡</span></p>
                </Col>
                <Col className="tacos-summary">
                    <CardColumns>
                        <Card className="bg-light border-light">
                            <Card.Body className="p-2">
                                There is nothing wrong in going wrong!
                            </Card.Body>
                        </Card>
                        <Card className="bg-light border-light">
                            <Card.Body className="p-2">
                                Accept failures, don't justify!
                            </Card.Body>
                        </Card>
                        <Card className="bg-light border-light">
                            <Card.Body className="p-2">
                                Your work should give you an edge.
                            </Card.Body>
                        </Card>
                        <Card className="bg-light border-light p-0">
                            <Card.Body className="p-2">
                                Accept the loss with a smile
                            </Card.Body>
                        </Card>
                        <Card className="bg-light border-light">
                            <Card.Body className="p-2">
                                If you study you get marks, If you learn you get a skill.
                            </Card.Body>
                        </Card>
                        <Card className="bg-light border-light">
                            <Card.Body className="p-2">
                                Just believe.
                            </Card.Body>
                        </Card>
                    </CardColumns>
                </Col>
            </Row>
        </Container>
    );
};

export default TacosSummary;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Testimonials = () => (
    <Container>
        <Row>
            <Col>
                <h2 className="text-center color__royalblue">How I satisfied them...</h2>
                <hr width="50px" color="red"></hr>
            </Col>
        </Row>
        <br></br>
        <Row>
            <Col>
                <Container>
                    <Row className="align-center">
                        <Col sm="8">
                            <Carousel fade indicators={false} className="text-center testimonial-carousel"
                                prevIcon={<FontAwesomeIcon icon={faChevronCircleLeft} size="lg" color="red" />}
                                nextIcon={<FontAwesomeIcon icon={faChevronCircleRight} size="lg" color="red" />}
                            >
                                <Carousel.Item>
                                    <Card className="text-left testimonial-card">
                                        <Card.Body>                                            
                                            <Card.Text>
                                            Nithin is an excellent automation developer
                                            and performs well on both large scale opportunities
                                            and small tactical solutions.
                                            Firmly recommend his skills in this space.
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="text-muted bg-transparent">Ray Beasley, Scrum Master at AXA</Card.Footer>
                                    </Card>    
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Card className="text-left testimonial-card">
                                        <Card.Body>                                            
                                            <Card.Text>
                                            Nithin has exemplary knowledge in the field of Automation.
                                            He is not only a skilled technocrat but also a great mentor.
                                            Other praiseworthy thing is his patience and art of communication. I recommend him as a skilled professional and trainer.
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="text-muted bg-transparent">Anurag Upadhyay, Digital Consultant at PWC India</Card.Footer>
                                    </Card>    
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Card className="text-left testimonial-card">
                                        <Card.Body>                                            
                                            <Card.Text>
                                            I have worked with Nithin.
                                            He is quick in solving problems and has .Net skills.
                                            Cool and innovative. The solution he proposed for Uipath hackathon is outstanding.
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="text-muted bg-transparent">Bannari Shankar, RPA Developer at TCS</Card.Footer>
                                    </Card>    
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Card className="text-left testimonial-card">
                                        <Card.Body>                                            
                                            <Card.Text>
                                            He has always been a very good asset to the team and never hesitates to share his knowledge with others.
                                            He is a good learner, worker and a trainer.
                                            I, personally have learnt a lot from him.
                                            His knowledge, hard work, determination and dedication has always remained impeccable and I wish it continues. 
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="text-muted bg-transparent">Gayathri Ramanathan, RPA Developer at TCS</Card.Footer>
                                    </Card>    
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Card className="text-left testimonial-card">
                                        <Card.Body>                                            
                                            <Card.Text>
                                            I worked with nithin and attended RPA workshops, 
                                            his way of explanation is very clear and one can understand very easily. 
                                            He is very much clear with concepts I can recommend nithin for RPA learners. 
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="text-muted bg-transparent">Kishore Mopur, Software Developer at Legato</Card.Footer>
                                    </Card>    
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>    
            </Col>
        </Row>
    </Container>
);

export default Testimonials;
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import Home from '../components/Home';
import About from '../components/About';
import Blog from '../components/Blog';
import Tacos from '../components/Tacos';
import Contact from '../components/Contact';
import BlogTest  from '../playground/blog-test';

const AppRouter = () => {

    return (
    <BrowserRouter>
        <Header />
        <Menu />
        <Switch>
            <Route path="/" component={Home} exact={true} />
            <Route path="/home" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/blog" component={Blog} />
            <Route path='/blog-test' component={BlogTest} />
            <Route path="/tacos" component={Tacos} />
            <Route path="/contact" component={Contact} />
        </Switch>
        <Footer />
    </BrowserRouter>
)};

export default AppRouter;
import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import GithubCalendar from 'react-github-calendar';


const Contributions = () => {

    return (
        <Container>
            <Row>
                <Col>   
                    <h2 className='text-center mt-5 color__royalblue'>Keeping myself updated</h2>
                    <p className="text-muted text-center">An average of 1contribution per day :)</p>
                    <hr width="50px" color="red"></hr>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col><h3 className="text-center">Github</h3></Col>
            </Row>
            <br></br>
            <Row>
                <Col className="align-center">
                    <GithubCalendar username="nmnithinkrishna" fullYear={false} blockSize={16} />
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col><h3 className="text-center">HackerRank</h3></Col>
            </Row>
            <br></br>
            <Row className="align-center">
                <Col className="align-center">
                    <Image src="/media/30DaysOfCode.png" />
                </Col>
                <Col className="align-center">
                    <Image src="/media/SQL.png" />
                </Col>
                <Col className="align-center">
                    <Image src="/media/Python.png" />
                </Col>
                <Col className="align-center">
                    <Image src="/media/Problem_Solving.png" />
                </Col>
            </Row>
        </Container>
    );
};

export default Contributions;
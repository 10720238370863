import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SocialMenu from './sections/Header/SocialMenu';
import { Link } from 'react-router-dom';

const Header = () => (    
    <Container>
        <br />
        <Row>
            <Col sm={12} lg className="logo-container">
                <Link to="/" className="logo-container__text">
                    <h1>nmnithinkrishna<span>, technologist</span></h1>
                </Link>
            </Col>
            <Col>
                <SocialMenu />
            </Col>
        </Row>
        <hr></hr>
    </Container>
);

export default Header;
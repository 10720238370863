import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const Highlights = () => (
    <Container>
        <Row>
            <Col>
                <h2 className="text-center color__royalblue">Am I capable<span role="img" aria-label="wink"> 😉</span></h2>
                <hr width="50px" color="red"></hr>
            </Col>
        </Row>
        <br></br>
        <Row>
            <Col sm="12" md>
                <Card className="p-3 border-0">
                    <Card.Img variant="top" src="/media/tie.svg" />
                    <Card.Body className="text-center">
                        <Card.Title>~4years of technical expertise</Card.Title>
                        <Card.Text className="text-muted">
                            It's been four years since I started my career. It all happened with TCS ensuring quality learning, building, upskilling & bunch of memories.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md>
                <Card className="p-3 border-0">
                    <Card.Img variant="top" src="/media/coding.svg" />
                    <Card.Body className="text-center">
                        <Card.Title>20+ projects delivered</Card.Title>
                        <Card.Text className="text-muted">
                            Developed full stack .NET web applications with MVC and automated business processes using RPA in insurance, healthcare, finance areas.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md>
                <Card className="p-3 border-0">
                    <Card.Img variant="top" src="/media/trophy.svg" />
                    <Card.Body className="text-center">
                        <Card.Title className="text-center">Hackathon winner</Card.Title>
                        <Card.Text className="text-muted">
                            Active participation in tech events that provides opportunity to learn. I was awarded top spot for providing innovative & flawless RPA solution in less than 24hrs.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <br></br>
        <Row>
            <Col sm="12" md>
                <Card className="p-3 border-0">
                    <Card.Img className="" variant="top" src="/media/medal.svg" />
                    <Card.Body className="text-center">
                        <Card.Title className="text-center">RPA certified developer</Card.Title>
                        <Card.Text className="text-muted">
                            Credential to prove my skills. I'm an RPA Advanced Developer certified by UiPath. This shows my brief idea about development, infrastructure and security.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md>
                <Card className="p-3 border-0">
                    <Card.Img className="" variant="top" src="/media/think-out-of-the-box.svg" />
                    <Card.Body className="text-center">
                        <Card.Title className="text-center">Breaking the bubble</Card.Title>
                        <Card.Text className="text-muted">
                            Diversification is my strength. I'm independant of technology, adaptable to any tech solving business problems by building efficient logic.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm="12" md>
                <Card className="p-3 border-0">
                    <Card.Img className="" variant="top" src="/media/people.svg" />
                    <Card.Body className="text-center">
                        <Card.Title className="text-center">Trained 100+ people</Card.Title>
                        <Card.Text className="text-muted">
                            Learning and sharing compounds my growth. 
                            I see this as a great chance to explore and 
                            serve people to understand any technology in a better way.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);

export default Highlights;